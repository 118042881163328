var originalKeywords;

$(document).ready(function () {
  originalKeywords = $('#keywords').val();
});

function search() {
  var keywords = $('#keywords').val();

  if (keywords !== originalKeywords) {
    // Disable semester refinements.
    $.each($('select[id!=filter]'), function (i, val) {
      val.disabled = true;
    });
  }
}
